import { EmboltAPI } from './embolt_api';

const UPDATE_TASK_PATH = '/api/v1/admin/registration_tasks/:id'

class OnboardTasksAPI extends EmboltAPI {
  async update(taskId, completed) {
    const url = UPDATE_TASK_PATH.replace(':id', taskId);

    return await fetch(url, {
      method: 'PATCH',
      headers: this.apiHeaders,
      body: JSON.stringify({ completed }),
    });
  }
}

export { OnboardTasksAPI }