import { Controller } from '@hotwired/stimulus';
import { makeElement } from '../../lib/utils';

export default class extends Controller {
  static targets = [
    'taskContainer',
    'task'
  ];

  static values = {
    'newTaskTemplate': String,
    'ordinal': Number,
  }

  newTask(event) {
    const ordinalizedTemplate = this.newTaskTemplateValue.replace(/idx/g, this.nextOrdinal());
    const el = makeElement(ordinalizedTemplate);

    this.taskContainerTarget.appendChild(el);

    if (this.taskTargets.length > 1) {
      this.taskTargets.forEach(task => {
        task.querySelector('[data-role="delete"]').classList.remove('d-none');
      });
    }
  }

  removeTask(event) {
    const task = event.target.closest('div[data-role="task"]');
    const taskOrdinal = task.querySelector('input[name*="ordinal"]');

    task.classList.add('d-none');
    taskOrdinal.disabled = true;

    const destroyInput = document.createElement('input');
    destroyInput.type = 'hidden';
    destroyInput.name = `registration_task_list[registration_task_plans_attributes][${taskOrdinal.value}][_destroy]`
    destroyInput.value = 1;

    task.appendChild(destroyInput);

    const enabledTaskTargets = this.taskTargets.filter(e => e.querySelector('input[name*="ordinal"]:enabled'));

    enabledTaskTargets.forEach((task, index) => {
      task.querySelector('input[name*="ordinal"]:enabled').value = index + 1;
      task.querySelector('label').innerHTML = `Task #${index + 1}`;

      // If we're down to just one task, remove the delete button
      if (this.taskTargets.length === 1) {
        task.querySelector('[data-role="delete"]').classList.add('d-none');
      };
    });

    this.ordinalValue = enabledTaskTargets.length;
  }

  nextOrdinal() {
    return ++this.ordinalValue;
  }
}