import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'selectAll', 'field', 'form', 'formatSelect',
    'csvOptionsFieldset', 'noHeaderCheckbox', 'separatorRadio',
  ];

  toggleAll(event) {
    const { checked } = event.target;

    this.fieldTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }

  toggleFormat(event) {
    const { value } = event.target

    if (value === 'CSV') {
      this.csvOptionsFieldsetTarget.classList.remove('d-none');
      this.noHeaderCheckboxTarget.disabled = false;
      this.separatorRadioTarget.disabled = false;
    } else {
      this.csvOptionsFieldsetTarget.classList.add('d-none');
      this.noHeaderCheckboxTarget.disabled = true;
      this.separatorRadioTarget.disabled = true;
    }

  }
}
