import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const CARD_TARGETS = [
  "activeMembers", "newMembers", 'expiringMembers',
  'membersWithTasks', 'completedTasks', 'outstandingTasks'
].flatMap((key) => [`${key}Value`, `${key}Gain`, `${key}Loss`, `${key}GL`]);

export default class extends Controller {
  static targets = [
    ...CARD_TARGETS,
    'activeMembersTimeseries',
    'memberDistributionPie',
    'registrationsBarChart',
  ];

  static values = {
    'tasksEnabled': Boolean
  };

  async connect() {
    Chart.register(ChartDataLabels);

    const { metrics, timeseries } = await this.fetchMetrics();

    this.renderStats(metrics);
    this.renderGraphs(metrics, timeseries);
  }

  async fetchMetrics() {
    const response = await fetch('/api/v1/members/summary', {
      method: 'GET',
      headers: this.apiHeaders,
    });

    const { summary } = await response.json();

    return summary;
  }

  renderStats(metrics) {
    const { members: memberMetrics } = metrics;
    const {
      active: activeMembers,
      active_in_period: activeMembersInPeriod,
      new: newMembers,
      new_in_period: newMembersInPeriod,
      expiring: expiringMembers,
      members_with_tasks: membersWithTasks,
      members_with_tasks_in_period: membersWithTasksInPeriod,
      completed_tasks: completedTasks,
      completed_tasks_in_period: completedTasksInPeriod,
      outstanding_tasks: outstandingTasks,
      outstanding_tasks_in_period: outstandingTasksInPeriod,
    } = memberMetrics;

    const activeMembersGainLoss = (activeMembers / (activeMembers - activeMembersInPeriod)) - 1.0;
    const newMembersGainLoss = (newMembers / (newMembers - newMembersInPeriod)) - 1.0;
    const membersWithTasksGainLoss = (membersWithTasks / (membersWithTasks - membersWithTasksInPeriod)) - 1.0;
    const completedTasksGainLoss = (completedTasks / (completedTasks - completedTasksInPeriod)) - 1.0;
    const outstandingTasksGainLoss = (outstandingTasks / (outstandingTasks - outstandingTasksInPeriod)) - 1.0;

    this.renderCardStat('activeMembers', activeMembers, activeMembersGainLoss);
    this.renderCardStat('newMembers', newMembers, newMembersGainLoss);
    this.renderCardStat('expiringMembers', expiringMembers);

    this.renderCardStat('membersWithTasks', membersWithTasks, membersWithTasksGainLoss, true);
    this.renderCardStat('completedTasks', completedTasks, completedTasksGainLoss);
    this.renderCardStat('outstandingTasks', outstandingTasks, outstandingTasksGainLoss, true);
  }

  renderGraphs(metrics, timeseries) {
    const { active_members: activeMembersTimeseries, registrations: registrationsTimeseries } = timeseries;
    const { members: memberMetrics } = metrics;
    const { active, expired, new: newMembers } = memberMetrics;

    const pieData = [
      { label: 'Recurring Members', value: active - newMembers, color: 'rgba(13, 155, 164, 0.4)' },
      { label: 'New Members', value: newMembers, color: 'rgba(47, 137, 197, 0.4)' },
      { label: 'Expired Members', value: expired, color: 'rgba(231, 76, 60, 0.4)' },
    ];

    this.graphActiveMembersTimeseries(activeMembersTimeseries);
    this.graphRegistrationsBarChart(registrationsTimeseries);
    this.graphMemberDistributionPie(pieData);
  }

  renderCardStat(target, value, gainLoss, inverseGL = false) {
    this[`${target}ValueTarget`].innerText = value;

    if (gainLoss !== null && !isNaN(gainLoss) && isFinite(gainLoss) && gainLoss !== 0) {
      const direction = gainLoss >= 0 ? 'Gain' : 'Loss';

      let colorClass;

      if (inverseGL) {
        colorClass = gainLoss >= 0 ? 'system-red-6' : 'primary-7';
      } else {
        colorClass = gainLoss >= 0 ? 'primary-7' : 'system-red-6';
      }

      const formattedGainLoss = gainLoss.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 });

      this[`${target}GLTarget`].classList.add(colorClass);
      this[`${target}GLTarget`].innerText = formattedGainLoss;

      this[`${target}${direction}Target`].classList.remove('d-none');
      this[`${target}${direction}Target`].classList.add(colorClass);
    }
  }

  graphActiveMembersTimeseries(timeseriesData) {
    const element = this.activeMembersTimeseriesTarget;
    const gradient = element.getContext('2d').createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(13, 155, 164, 0.6)');
    gradient.addColorStop(.7, 'rgba(100, 191, 197, 0.01)');

    const labels = timeseriesData.map(d => d.label);
    const values = timeseriesData.map(d => d.value);

    const data = {
      labels: labels,
      datasets: [{
        label: 'Active Members',
        data: values,
        fill: true,
        backgroundColor: gradient,
        borderColor: '#0D9BA4',
        segment: {
          borderColor: '#0D9BA4'
        },
        tension: .3
      }]
    };
    const config = {
      type: 'line',
      data: data,
      options: {
        radius: 0,
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          intersect: false,
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: false
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            }
          },
          y: {
            display: true,
            beginAtZero: true,
            grid: {
              display: false,
            },
          }
        }
      }
    };

    new Chart(element, config)
  };

  graphRegistrationsBarChart(barData) {
    const element = this.registrationsBarChartTarget;

    const labels = barData.map(d => d.label);
    const values = barData.map(d => d.value);

    const data = {
      labels: labels,
      datasets: [{
        label: 'Registrations in period',
        data: values,
        backgroundColor: [
          'rgba(47, 137, 197, 0.2)',
          'rgba(47, 137, 197, 0.2)',
          'rgba(47, 137, 197, 0.2)',
          'rgba(47, 137, 197, 0.2)',
        ],
        borderColor: [
          'rgba(47, 137, 197, 1)',
        ],
        borderWidth: 1,
      }]
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: false,
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            }
          },
          y: {
            display: true,
            beginAtZero: true,
            grid: {
              display: false,
            },
          }
        }
      }
    };

    new Chart(element, config);
  };

  graphMemberDistributionPie(pieData) {
    const element = this.memberDistributionPieTarget;

    const labels = pieData.map(d => d.label);
    const values = pieData.map(d => d.value);
    const colors = pieData.map(d => d.color);

    const config = {
      type: 'doughnut',
      data: {
        labels: labels,
        datasets: [{
          label: 'Member Breakdown',
          data: values,
          backgroundColor: colors,
          borderColor: [
            'rgba(13, 155, 164, 1)',
            'rgba(47, 137, 197, 1)',
            'rgba(231, 76, 60, 1)',
          ],
          borderWidth: 1,
          hoverOffset: 4
        }]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            formatter: (value, ctx) => {
              const dataset = ctx.chart.data.datasets[0];
              const sum = dataset.data.reduce((a, b) => a + b, 0);

              if (!sum) {
                return '';
              }

              return Math.round((value / sum) * 100) + '%';
            },
            color: '#172432',
          }
        }
      }
    }

    new Chart(element, config)
  };

  get apiHeaders() {
    const csrfToken = $('meta[name="csrf-token"]').attr('content');
    const apiHeaders = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    }

    return apiHeaders;
  }
}
