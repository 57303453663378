import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['bulkIdCheckbox', 'batchForm', 'filterForm', 'exportSelectedRow'];

  exportSelected(event) {
    const { action } = event.params;

    this.batchFormTarget.setAttribute('data-turbo-frame', 'modal');
    this.batchFormTarget.action = action;
    this.batchFormTarget.requestSubmit();
  }

  exportAll(event) {
    const { action } = event.params;

    const oldAction = this.filterFormTarget.action;

    this.filterFormTarget.setAttribute('data-turbo-frame', 'modal');
    this.filterFormTarget.action = action;
    this.filterFormTarget.requestSubmit();
    this.filterFormTarget.action = oldAction;

  }

  selectAll(event) {
    const { checked } = event.target;

    if (checked) {
      this.exportSelectedRowTarget.classList.remove('disabled')
    } else {

      this.exportSelectedRowTarget.classList.add('disabled')
    }

    this.bulkIdCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = checked;
    });
  }

  select(event) {
    const { checked } = event.target;

    if (checked) {
      this.exportSelectedRowTarget.classList.remove('disabled')
    } else if (this.bulkIdCheckboxTargets.filter((checkbox) => checkbox.checked).length === 0) {
      this.exportSelectedRowTarget.classList.add('disabled')
    }
  }
}