import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static values = { noOpen: { type: Boolean, default: false } };

  connect() {
    this.modal = new bootstrap.Modal(this.element);
  }

  open() {
    if (!this.noOpenValue) {
      this.modal.show();
    }
  }

  close(event) {
    // Do not close the modal if we're completing a submission, we'll
    // let other handlers decide whether the modal is to be closed
    const { type, detail } = event;
    const { success } = (detail || {});

    if (type != "turbo:submit-end" || success) {
      this.modal.hide();

      // Setting this value to true will prevent the modal from being re-opened
      // when another action is clicked -- I don't actually know why I had this
      // behavior in the first place but if you've come to uncomment this line
      // be aware of WHY it's commented out in the first place.
      // this.noOpenValue = true;
    }
  }
}
