import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'input',
    'container',
    'secondaryInput',
    'secondaryContainer',
    'fieldToggle'
  ]

  static values = {
    toggled: Boolean,
    secondaryToggled: Boolean
  }

  toggleFilter(event) {
    if (!this.isToggleEvent(event)) return;

    this.toggledValue = !this.toggledValue;
    this.fieldToggleTarget.checked = this.toggledValue;

    this.containerTargets.forEach((container) => {
      container.classList.toggle('d-none');
    });

    this.inputTargets.forEach((input) => {
      input.disabled = !input.disabled;
    });

    if (this.toggledValue && this.secondaryToggledValue) {
      this.enableSecondary();
    } else {
      this.disableSecondary();
    }
  }

  handleSelect(event) {
    const optionParams = event.target.options[event.target.selectedIndex];
    const { showSecondary } = optionParams.dataset;

    this.secondaryToggledValue = showSecondary === 'true';

    if (this.secondaryToggledValue) {
      this.enableSecondary();
    } else {
      this.disableSecondary();
    }
  }

  enableSecondary() {
    this.secondaryContainerTargets.forEach((container) => {
      container.classList.remove('d-none');
    });

    this.secondaryInputTargets.forEach((input) => {
      input.disabled = false;
    });
  }

  disableSecondary() {
    this.secondaryContainerTargets.forEach((container) => {
      container.classList.add('d-none');
    });

    this.secondaryInputTargets.forEach((input) => {
      input.disabled = true;
    });
  }

  isToggleEvent(event) {
    return event.target.dataset.role === 'toggle';
  }
}

