
class EmboltAPI {
  constructor(csrfToken) {
    this.csrfToken = csrfToken;
  }

  get apiHeaders() {
    const apiHeaders = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': this.csrfToken,
    }

    return apiHeaders;
  }
}

export { EmboltAPI };