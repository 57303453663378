import { EmboltAPI } from "./embolt_api";

const COMPLETE_REGISTRATION_TASKS_PATH = '/api/v1/registrations/:id/complete-tasks';

class RegistrationsAPI extends EmboltAPI {
  async completeTasks(registrationId) {
    const url = COMPLETE_REGISTRATION_TASKS_PATH.replace(':id', registrationId);

    return await fetch(url, {
      method: 'POST',
      headers: this.apiHeaders,
      body: JSON.stringify({}),
    });
  }
}

export { RegistrationsAPI };