import { Controller } from '@hotwired/stimulus';
import { APIClient } from '../../lib/api_client';

export default class extends Controller {
  static targets = [
    'todoLabel',
    'tableView',
    'noTasksView'
  ];

  static values = {
    todoCount: Number
  };

  connect() {
    this.apiClient = new APIClient();
  }

  async updateTask(event) {
    const { params } = event;
    const { id: taskId } = params;

    const checkboxTarget = this.findCheckbox(event.target);

    // If the element clicked is not the checkbox, simulate
    // the toggling of the checkbox
    if (checkboxTarget !== event.target) {
      checkboxTarget.checked = !checkboxTarget.checked;
    }

    const checked = checkboxTarget.checked;

    try {
      await this.apiClient.onboard_tasks.update(taskId, checked);
    } catch (error) {
      console.error(error);
    }

    this.toggleDoneButton(checkboxTarget);
  }

  async completeTasks(event) {
    const { params } = event;
    const { registrationId } = params;

    try {
      this.apiClient.registrations.completeTasks(registrationId);
    } catch (error) {
      console.error(error);
    }

    this.findObjectRow(event.target).remove();
    this.todoLabelTarget.textContent = --this.todoCountValue;
  }

  todoCountValueChanged() {
    if (this.todoCountValue === 0) {
      this.showTasksCompleteScreen();
    }
  }

  showTasksCompleteScreen() {
    this.tableViewTarget.classList.add('d-none');
    this.noTasksViewTarget.classList.remove('d-none');
  }

  findCheckbox(element) {
    // If the element itself is the input, we're good
    if (element.tagName === 'INPUT') {
      return element;
    }

    return element.closest('div[data-role="checkboxContainer"]').querySelector('input');
  }

  findObjectRow(element) {
    return element.closest('tr[data-role="objectRow"]');
  }

  toggleDoneButton(checkboxTarget) {
    const tableRow = this.findObjectRow(checkboxTarget);
    const allTasks = tableRow.querySelectorAll('input[type="checkbox"]');
    const allChecked = Array.from(allTasks).every((task) => task.checked);

    if (allChecked) {
      tableRow.querySelector('button[data-role="doneButton"]').removeAttribute('disabled');
    } else {
      tableRow.querySelector('button[data-role="doneButton"]').setAttribute('disabled', 'disabled');
    }
  }
};
