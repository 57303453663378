import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['toast'];

  connect() {
    this.toastTargets.forEach((toast) => {
      new bootstrap.Toast(toast).show();
    })
  }
}