import { Controller } from "@hotwired/stimulus"

const CHECKBOX_TYPE = 'CheckboxField';
const RADIO_TYPE = 'RadioField';
const TEXT_TYPE = 'TextField';
const DROPDOWN_TYPE = 'DropdownField';

const FIELD_TYPE_LABELS = {
  [CHECKBOX_TYPE]: '(Checkbox Field)',
  [RADIO_TYPE]: '(Radio Buttons)',
  [TEXT_TYPE]: '(Text Field)',
  [DROPDOWN_TYPE]: '(Dropdown Field)'
}

const FIELD_PREVIEW_TEXT = {
  [TEXT_TYPE]: 'Your text here',
  [DROPDOWN_TYPE]: 'Select an option'
}

const REQUIRES_COLLECTION = [RADIO_TYPE, DROPDOWN_TYPE];

class PillFactory {
  constructor(template) {
    this.template = template;
  }

  make(label, options = { labelOnly: true, ordinal: null }) {
    const decoder = document.createElement('textarea');
    decoder.innerHTML = this.template.replace(/idx/g, options.ordinal);

    const pillContainer = document.createElement('div');
    pillContainer.innerHTML = decoder.value;

    const pill = pillContainer.firstChild;

    if (options.labelOnly) {
      pill.querySelector('input').remove();
      pill.querySelector('button').classList.remove('d-none');
    } else {
      pill.querySelector('input').value = label;
      pill.querySelector('button').remove();
    }

    pill.querySelector('span[data-role="item"]').innerHTML = label;

    return pill;
  }
}

class Field {
  constructor(element, pillFactory) {
    this.element = element;
    this.pillFactory = pillFactory;
  }

  get htmlElement() {
    return this.element;
  }

  set name(name) {
    this._name = name;

    this.element.querySelectorAll('span[data-role="fieldLabel"]').forEach(
      (e) => e.innerHTML = name
    );
    this.element.querySelectorAll('input[data-role="fieldLabel"]').forEach(
      (e) => e.value = name
    );
  }

  set type(type) {
    this._type = type;

    this.element.querySelectorAll('span[data-role="fieldType"]').forEach(
      (e) => e.innerHTML = FIELD_TYPE_LABELS[type]
    );
    this.element.querySelectorAll('input[data-role="fieldType"]').forEach(
      (e) => e.value = type
    );

    if (FIELD_PREVIEW_TEXT[type]) {
      this.element.querySelectorAll('input[data-role="preview"]').forEach(
        (e) => e.value = FIELD_PREVIEW_TEXT[type]
      )
    }
  }

  set ordinal(ordinal) {
    this._ordinal = ordinal;

    this.element.querySelectorAll('input[data-role="fieldOrdinal"]').forEach(
      (e) => e.value = ordinal
    );
  }

  set collection(collection) {
    this._collection = collection;

    const dropdownOptionsContainer = this.element.querySelector(
      '[data-role="fieldItemContainer"]'
    );

    collection.forEach(
      (itemLabel) => {
        const newOption = this.pillFactory.make(
          itemLabel, { labelOnly: false, ordinal: this._ordinal }
        );

        dropdownOptionsContainer.appendChild(newOption);
      }
    );
  }

  enableField() {
    this.element.querySelector('[data-role="fieldRequired"]').value = false;

    switch (this._type) {
      case CHECKBOX_TYPE:
        this.element.querySelector('[data-role="textPreview"]').classList.add('d-none');
        this.element.querySelector('[data-role="checkboxPreview"]').classList.remove('d-none');
        break;
      case DROPDOWN_TYPE:
        this.element.querySelector('[data-role="fieldItemContainer"]').classList.remove('d-none');
        this.element.querySelector('[data-role="textPreview"]').classList.add('d-none');
        this.element.querySelector('[data-role="dropdownPreview"]').classList.remove('d-none');
        break;
      case RADIO_TYPE:
        this.element.querySelector('[data-role="textPreview"]').classList.add('d-none');
        this.element.querySelector('[data-role="radioPreview"]').classList.remove('d-none');

        const radioContainer = this.element.querySelector('[data-role="radioItemContainer"]');

        this._collection.forEach((itemLabel) => {
          const wrapper = document.createElement('div');
          wrapper.classList.add('form-check', 'd-flex', 'align-items-center', 'me-3');
          wrapper.innerHTML = `
              <input class="form-check-input radio_buttons optional me-2"
                name="membership[fields_attributes][${this._ordinal}][collection][]"
                type="radio"
                disabled
                value="${itemLabel}" />
              <label class="form-check-label collection_radio_buttons">
                ${itemLabel}
              </label>
          `;

          radioContainer.appendChild(wrapper);
        });
        break;
      case TEXT_TYPE:
        break;
    }
  }
}

class FieldFactory {
  constructor(template, pillFactory) {
    this.template = template;
    this.pillFactory = pillFactory;
  }

  make(name, type, ordinal, collection) {
    const errors = [];

    if (!name) {
      errors.push('MISSING_NAME');
    }

    if (REQUIRES_COLLECTION.includes(type) && (!collection || !collection.length)) {
      errors.push('MISSING_COLLECTION');
    }

    if (errors.length) {
      throw { reasons: errors }
    }

    const newField = this.createFieldFromTemplate(ordinal);

    newField.name = name;
    newField.type = type;
    newField.ordinal = ordinal;
    newField.collection = collection;
    newField.enableField();

    return newField.htmlElement;
  }

  createFieldFromTemplate(ordinal) {
    const decoder = document.createElement('textarea');
    decoder.innerHTML = this.template.replace(/idx/g, ordinal);

    const fieldConainter = document.createElement('div');
    fieldConainter.innerHTML = decoder.value;

    return new Field(fieldConainter.firstChild, this.pillFactory);
  }
}

class MembershipTierFactory {
  constructor(template) {
    this.template = template;
  }

  make(ordinal) {
    const decoder = document.createElement('textarea');
    decoder.innerHTML = this.template.replace(/idx/g, ordinal);

    const membershipTierContainer = document.createElement('div');
    membershipTierContainer.innerHTML = decoder.value;

    return membershipTierContainer.firstChild;
  }
}

export default class extends Controller {
  static targets = [
    'newFieldName',
    'newFieldTypeOption',
    'newFieldCollection',
    'newFieldItemInput',
    'newFieldItemContainer',
    'collectionBuilderContainer',
    'customFieldsContainer',
    'membershipTiersContainer',
    'addMembershipTierButtonContainer',
    'defaultTier',
  ];

  static values = {
    fieldTemplate: String,
    pillTemplate: String,
    membershipTierTemplate: String,
    currentFieldOrdinal: Number,
    currentMembershipTierOrdinal: Number,
  }

  connect() {
    this.pillFactory = new PillFactory(this.pillTemplateValue);
    this.fieldFactory = new FieldFactory(this.fieldTemplateValue, this.pillFactory);
    this.membershipTierFactory = new MembershipTierFactory(this.membershipTierTemplateValue);
  }

  onKeyEnter(event) {
    if (
      !this.collectionBuilderContainerTarget.classList.contains('d-none')
      && this.newFieldItem
    ) {
      this.addItem(event);
      return;
    }

    this.addField(event);

  }

  addItem(event) {
    if (!this.newFieldItem) {
      this.newFieldItemInputTarget.classList.add('is-invalid');
      return;
    };

    this.newFieldItemInputTarget.classList.remove('is-invalid');

    const pill = this.pillFactory.make(this.newFieldItem, { labelOnly: true });

    this.newFieldItemContainerTarget.appendChild(pill);
    this.newFieldItemInputTarget.value = '';
  }

  addField(event) {
    this.newFieldNameTarget.classList.remove('is-invalid');
    this.newFieldItemInputTarget.classList.remove('is-invalid');

    const fieldName = this.newFieldName;
    const fieldType = this.newFieldType;
    const ordinal = this.nextFieldOrdinal();
    const collection = this.collectItems();

    try {
      const newField = this.fieldFactory.make(fieldName, fieldType, ordinal, collection);

      this.customFieldsContainerTarget.appendChild(newField);

      this.newFieldItemContainerTarget.innerHTML = '';
      this.newFieldNameTarget.value = '';
    } catch (error) {
      console.error(error);

      const reasons = error.reasons || [];

      if (reasons.includes('MISSING_NAME')) {
        this.newFieldNameTarget.classList.add('is-invalid');
      }

      if (reasons.includes('MISSING_COLLECTION')) {
        this.newFieldItemInputTarget.classList.add('is-invalid');
      }
    }
  }

  toggleShowMembershipTiers(event) {
    const checked = event.target.checked;

    if (checked) {
      this.addMembershipTierButtonContainerTarget.classList.remove('d-none');
      this.membershipTiersContainerTarget.classList.remove('d-none');
      this.defaultTierTarget.querySelector('[data-role="line"]').classList.remove('d-none');
      this.defaultTierTarget.querySelector('[data-role="name"]').classList.remove('d-none');
    } else {
      this.addMembershipTierButtonContainerTarget.classList.add('d-none');
      this.defaultTierTarget.querySelector('[data-role="line"]').classList.add('d-none');
      this.defaultTierTarget.querySelector('[data-role="name"]').classList.add('d-none');

      // Clear any now hidden tiers
      this.membershipTiersContainerTarget.classList.add('d-none');
      this.membershipTiersContainerTarget.innerHTML = '';
    }
  }

  addMembershipTier(event) {
    const ordinal = this.nextMembershipTierOrdinal();
    const membershipTier = this.membershipTierFactory.make(ordinal);

    this.membershipTiersContainerTarget.appendChild(membershipTier);
  }

  collectItems(event) {
    const options = [];

    this.newFieldItemContainerTarget.querySelectorAll('span[data-role="item"]').forEach(
      (e) => options.push(e.innerHTML)
    );

    return options;
  }

  removeTier(event) {
    event.target.closest('div[data-role="membershipTier"]').remove();
  }

  removeField(event) {
    event.target.closest('div[data-role="customField"]').remove();
  }

  removePill(event) {
    event.target.closest('div[data-role="pill"]').remove();
  }

  showCollectionBuilder(event) {
    if (!event.target.checked) return;

    this.collectionBuilderContainerTarget.classList.remove('d-none');
  }

  hideCollectionBuilder(event) {
    if (!event.target.checked) return;

    this.collectionBuilderContainerTarget.classList.add('d-none');
  }

  nextFieldOrdinal() {
    return ++this.currentFieldOrdinalValue;
  }

  nextMembershipTierOrdinal() {
    return ++this.currentMembershipTierOrdinalValue;
  }

  get newFieldType() {
    return this.newFieldTypeOptionTargets.find(f => f.checked).value
  }

  get newFieldName() {
    return this.newFieldNameTarget.value
  }

  get newFieldItem() {
    return this.newFieldItemInputTarget.value
  }
}