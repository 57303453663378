import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'filterForm',
    'filterValue',
    'fieldToggle',
    'filtersModal',
  ];

  connect() {
    this.formState = this.filtersModalTarget.innerHTML;
    this.filtersModalTarget.addEventListener('hidden.bs.modal', this.cancelFilters.bind(this));
  }

  removeFilter(event) {
    const { key: fieldKey } = event.params;

    event.target.closest('div[data-role="applied-filter"').remove();

    const relatedField = this.fieldToggleTargets.find(input => input.dataset.key === fieldKey)

    const changeEvent = new Event('click', { bubbles: true });
    relatedField.dispatchEvent(changeEvent);

    this.filterFormTarget.submit();
  }

  submit(event) {
    let isValid = true;

    this.filterValueTargets.forEach((input) => {
      if (!input.disabled && !input.value) {
        input.classList.add('is-invalid');
        isValid = false;
      } else {
        input.classList.remove('is-invalid');
      }
    });

    if (isValid) {
      this.filterFormTarget.submit();
    }
  }

  cancelFilters(event) {
    this.filtersModalTarget.innerHTML = this.formState;
  }
}
