import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['paypalFee', 'emboltFee', 'takeHome', 'feeInputGroup'];

  connect() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          setTimeout(() => {
            this.feeInputGroupTarget.classList.add('tilt-shaking');
            setTimeout(() => {
              this.feeInputGroupTarget.classList.remove('tilt-shaking');
            }, 1000);
          }, 1000);
        }
      });
    });

    this.observer.observe(this.feeInputGroupTarget);
  }

  disconnect() {
    this.observer.disconnect();
  }

  calculate(event) {
    const input = event.target.value;
    const price = parseFloat(input);

    event.target.value = input.replace(/[^\d.]/g, '');

    if (isNaN(price) || price < 5) {
      this.paypalFeeTarget.innerHTML = '-';
      this.emboltFeeTarget.innerHTML = '-';
      this.takeHomeTarget.innerHTML = '-';
      return;
    }

    const paypalFee = price * 0.0349 + 0.49;
    const emboltFee = this.baseFee(price);
    const takeHome = price - paypalFee - emboltFee;

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    this.paypalFeeTarget.innerHTML = formatter.format(paypalFee);
    this.emboltFeeTarget.innerHTML = formatter.format(emboltFee);
    this.takeHomeTarget.innerHTML = formatter.format(takeHome);
  }

  baseFee(price) {
    if (price < 20) {
      return 0.99;
    }

    if (price < 50) {
      return 1.50;
    }

    if (price >= 50) {
      return 2.50;
    }
  }
}
