import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['newFieldContainer', 'email', 'addButton'];

  add(event) {
    const { template } = event.params;
    const { value: email } = this.emailTarget;

    if (!this._validateEmail(email)) {
      this._markInvalid();
      return
    }

    this._markValid();

    const builder = document.createElement('template');
    builder.innerHTML = template;

    const newAdmin = builder.content.firstChild;
    newAdmin.querySelector('input').value = email;
    newAdmin.querySelector('label').textContent = email;

    this.newFieldContainerTarget.appendChild(newAdmin);
    this.emailTarget.value = '';
    this.emailTarget.focus();

    this._toggleAddButton();
  }

  delete(event) {
    event.target.closest('div[data-role="emailContainer"]').remove();

    this._toggleAddButton();
  }

  _validateEmail(email) {
    const regex = /\S+@\S+\.\S+/;
    return regex.test(email);
  }

  _markInvalid() {
    this.emailTarget.classList.add('is-invalid');
  }

  _markValid() {
    this.emailTarget.classList.remove('is-invalid');
  }

  _toggleAddButton() {
    if (this.newFieldContainerTarget.childElementCount > 0) {
      this.addButtonTarget.disabled = false;
    } else {
      this.addButtonTarget.disabled = true;
    }
  }
}
