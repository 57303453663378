import { Controller } from '@hotwired/stimulus';
import BadWordsNext from 'bad-words-next';
import en from 'bad-words-next/data/en.json';

export default class extends Controller {
  static targets = ['name', 'subdomain', 'submit'];

  connect() {
    this.badWordChecker = new BadWordsNext({ data: en });

    if (this.nameTarget.value && this.subdomainTarget.value) {
      this.submitTarget.disabled = false;
    }
  }

  generate(event) {
    const { target } = event;
    const { value: organizationName } = target;

    const subdomain = this.makeSlug(organizationName);

    this.subdomainTarget.value = subdomain;
  }

  validate(event) {
    const { target } = event;
    const { value: organizationName } = target;

    if (this.badWordChecker.check(organizationName)) {
      this.submitTarget.disabled = true;
      target.classList.add('is-invalid');
    } else {
      this.submitTarget.disabled = false;
      target.classList.remove('is-invalid');
    }

    if (!this.nameTarget.disabled && !this.subdomainTarget.disabled) {
      this.submitTarget.disabled = false;
    }
  }

  makeSlug(organizationName) {
    const cleanedName = this.cleanString(
      organizationName.replace(/[^a-zA-Z ]/g, '')
    );

    const cleanedNameParts = cleanedName.split(' ');

    if (cleanedNameParts.length === 1 && cleanedName.length < 20) {
      return cleanedName.toLowerCase();
    }

    return this.buildCustomSlug(cleanedNameParts);
  }

  cleanString(value) {
    return this.badWordChecker.filter(value).replace(/\*/g, '').trim();
  }

  buildCustomSlug(parts) {
    let acronym = '';

    parts.forEach(word => {
      if (!/[A-Z]+/.test(word)) {
        if (word.length < 4) {
          acronym += word;
        } else {
          acronym += word.charAt(0);
        }
      } else {
        acronym += word;
      }
    });

    return this.cleanString(acronym).toLowerCase();
  }
}
