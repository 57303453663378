import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  goTo(event) {
    const { params } = event;
    const { scrollTo } = params;

    const element = this.element.querySelector(`[data-role="${scrollTo}"]`);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }
}